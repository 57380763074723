import { apiSlice } from './api.slice';

export const applicationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffer: builder.query({
      query: ({ offerSlug }) => ({
        url: `/applications/offers/${offerSlug}`,
        method: 'GET',
      }),
    }),
    addSubmission: builder.mutation({
      query: ({ offerSlug, data }) => ({
        url: `/applications/offers/${offerSlug}/submissions/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetOfferQuery, useAddSubmissionMutation } =
  applicationsApiSlice;
